.hero-root {
    background-image: radial-gradient(circle, rgba(177,179,181,0.25) 0%, rgba(178,218,179,0.25) 50%, rgba(113,168,149,.5) 100%);
    background-size: cover;
    background-position: center;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .hero-title {
        color: white;
        text-shadow: 2px 2px #000
    }
}