@media screen and (max-width: 900px) {
    .list-wrapper {
        padding: 0px !important;
    }    
}

.puregases-root {
    .puregases-list {
        text-align: center;
        padding: 50px 0;

        .list-title {
            padding-bottom: 50px;
        }
    }

    .puregases-grades {
        background-color: #71A895;
        color: white;
        text-align: center;
        padding: 50px 0;

        .grades-title {
            padding-bottom: 50px;
        }
    }

    .puregases-certification {
        padding: 50px 0;
        text-align: center;

        .certification-title {
            padding: 25px 0;
        }

        .certification-icon {
            width: 200px;
        }
    }
}