@media screen and (max-width: 900px) {
    .mappreview-wrapper {
        height: 500px;
    }
}

.contact-root {
    .contact-preview {
        .mappreview-wrapper {
            .preview-map {
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
        
        .locationsearch-wrapper {
            padding: 25px;
            
            .locationsearch-title {
                padding-bottom: 25px;
            }
            
            .location-search {
                width: 100%;

                .MuiOutlinedInput-root.Mui-focused fieldset, .Mui-focused {
                    color: #71a895;
                    border-color: #71a895 !important;
                }
            }
            
            .locationpreview-wrapper {
                margin: 25px 0;
            }

            .unbold {
                font-weight: normal !important;
            }
        }
    }
}