@media screen and (max-width: 600px) {
    .why-item-wrapper {
        padding-right: 16px !important;
    }

    .home-hero {
        min-height: 500px !important;
        align-items: center;

        .hero-logo {
            width: 350px !important;
            padding-top: 0px !important;
        }
    }
    .why-cylinder-filler {
        height: 400px !important;
        width: 100% !important;
        position: initial !important;
    }
}

@media screen and (max-width: 900px) {
    .services-container {
        margin-top: 0px !important;
    }    

    .why-hero-wrapper {
        padding: 50px 0px 25px 0px !important;
    }

    .contact-form-wrapper {
        padding: 50px 0 0 0 !important;
    }

    .why-container {
        padding-right: 0px !important;

        .why-item-wrapper {
            padding-right: 24px;
            
            .why-item-image {
                justify-content: center;

                .why-item-vector {
                    height: 100px;
                }
            }
        }

        .why-cylinder-filler {
            height: 600px !important;
            width: 100% !important;
            position: initial !important;
        }
    }

    .container {
        padding: 0 !important;
    }

    .home-hero {
        min-height: 500px !important;
        align-items: center;

        .hero-logo {
            padding-top: 0px !important;
        }
    }
}

.home-root {
    .home-hero {
        background: radial-gradient(circle, rgba(177,179,181,0.25) 0%, rgba(178,218,179,0.25) 50%, rgba(113,168,149,.5) 100%), url("../../imgs/banners/home-banner.jpg"); 
        background-position: right;
        background-size: cover;
        min-height: 776px;
        display: flex;
        align-items: top;
        justify-content: center;

        .hero-logo {
            width: 600px;
            height: 100%;
            padding-top: 75px;
        }
    }

    .home-services {
        .services-container {
            margin-top: -75px;

            .services-item {
                padding: 75px 15px;
                text-align: center;
                color: white;

                .services-icon {
                    height: 100px;
                }

                .services-title {
                    padding: 25px 0;
                }
            }

            .medical {
                background-color: #B1B3B5;
            }

            .cryogenic {
                background-color: #B2DAB3;
            }

            .laboratory {
                background-color: #71A895;
            }

            .food-beverage {
                background-color: #DBCB5D;
            }
        }
    }

    .home-why {
        .why-hero-wrapper {
            text-align: center;
            padding: 50px 150px 25px 150px;

            .why-title {
                padding-bottom: 25px;
            }
        }

        .why-item {
            padding: 25px 0;
            
            .why-item-image {
                display: flex;
                align-items: center;
            }
        }

        .why-item:last-child {
            padding-bottom: 50px;
        }

        .why-image-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            z-index: -1000;

            .why-cylinder-filler {
                height: 800px;
                width: 100%;
                max-width: 600px;
                position: absolute;
                right: 0;    
            }
        }
    }

    .home-preview {
        padding: 50px 0;

        .preview-title {
            text-align: center;
            padding-bottom: 50px;
        }

        .preview-wrap {
            height: 100%;
        }
        .preview-media {
            height: 300px;
        }

        .preview-button {
            background-color: #fff200;
            color: #3b3b3b;
        }

        .preview-button:hover {
            background-color: #DBCB5D;
        }
    }
}