.press-root {
  .press-heading {
    padding: 45px 0;
  }
  .press-preview {
    padding-bottom: 20px;
    a {
      color: inherit;
      text-decoration: inherit;
    }
    .press-img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .press-img {
        width: 100%;
      }
    }
    .press-author {
      border-top: 1px solid rgb(217, 217, 217);
      padding-top: 5px;
      font-style: italic;
    }
  }
}