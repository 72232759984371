.gascard-root {
    background-color: #71A895 !important;
    color: white !important;
    
    .gascard-head {
        padding: 50px 0;
        text-align: center;

        .head-img {
            height: 100px;
        }

        .head-title {
            padding-top: 25px;
        }
    }

    .gascard-body {
        .body-title {
            padding-top: 5px;
        }
    }

    .button-center {
        margin: 0 auto;
    }
}

.rotate {
    transform: rotate(180deg);
}

.gascard-root:hover {
    cursor: pointer;
}

.test {
    height: 200px;
    width: 100%;
}