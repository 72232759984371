.footer-root {
    padding: 25px;
    background-color: #3b3b3b;
    color: white;

    .footer-title {
        color: #fff200;
        font-weight: bold;
    }

    .footer-link {
        text-decoration: none;
        color: inherit;
    }

    .location-list {
        padding: 16px 0;
    }

    .contact-icon {
        color: #fff200;
    }

    .MuiListItem-gutters {
        padding-left: 0;
    }

    .MuiListItemText-primary {
        color: #fff200;
        font-weight: bold;
    }

    .MuiListItemText-secondary {
        color: white;
    }

    .footer-divider {
        border-color: #DBCB5D;
        margin-bottom: 16px;
    }

    .list-row {
        padding-bottom: 5px;
    }

    .list-row:last-child {
        padding-bottom: 0px !important;
    }
}