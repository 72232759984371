@media screen and (max-width: 900px) {
    .contact-form-wrapper {
        padding: 50px 0 0 0 !important;
    }
}

.contact-form-root {
    padding: 50px 0;
    background-color: #B2DAB3;
    color: white;
    text-align: center;

    .contact-form-wrapper {
        padding: 50px 150px 0 150px;

        .contact-textfield {
            margin-bottom: 25px;
        }

        .contact-captcha {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;
        }

        .contact-button {
            color: white;
            border: 1px solid white;
            border-radius: 0;
        }

        .MuiOutlinedInput-root {
            border-radius: 0px;
        }

        .MuiInputBase-root {
            color: white;
        }
        
        .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline:focus, .MuiOutlinedInput-notchedOutline:hover {
            border-color: white !important;
        }

        .MuiFormLabel-root {
            color: white !important;
        }

        .Mui-error, .Mui-error .MuiOutlinedInput-notchedOutline, .Mui-error .MuiOutlinedInput-notchedOutline:focus, .Mui-error .MuiOutlinedInput-notchedOutline:hover {
            color: #d32f2f !important;
            border-color: #d32f2f !important;
        }
    }
}