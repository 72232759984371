.navdesktop-root {
    background-color: #3b3b3b !important;
    .nav-toolbar {
        .nav-title {
            flex-grow: 1;
            color: #fff200;
        }

        .nav-link {
            color: #fff200;
            font-weight: bold;
        }

    }
}

.nav-menu {
    .MuiPaper-root {
        background-color: #3b3b3b;
        color: #fff200;
        border-radius: 0px 0px 4px 4px;
        border-top: 3px solid #fff200;
        margin-top: 14px;

        .MuiMenuItem-root  {
            font-weight: bold;
        }
    }
}