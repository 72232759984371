.about-root {
    .about-team {
        padding: 50px 0;
        
        .team-title {
            text-align: center;
            padding-bottom: 25px;
        }

        .team-list {
            text-align: center;

            .team-img {
                width: 250px;
                height: 100%;
            }
        }
    }
}