.scrolltop-root {
    z-index: 10000;
    .scrolltop-fab {
        background-color: #fff200 !important;
        color: #3b3b3b !important;
    }
    
    .scrolltop-fab:hover {
        background-color: #DBCB5D !important;
    }
}