@media screen and (max-width: 600px) {
    .telemetry-svg {
        height: 100px !important;
    }
}

.bulkmicrobulk-root {
    .bulkmicrobulk-telemetry {
        padding: 50px 0;
        text-align: center;

        .telemetry-title {
            padding-bottom: 25px;
        }

        .telemetry-svg {
            height: 150px;
            padding-bottom: 25px;
            margin: auto;
            display: flex;
        }
    }
}