.navmobile-root {
    background-color: #3b3b3b !important;

    .nav-toolbar {
        .nav-title, .nav-button {
            color: #fff200;
        }

        .nav-buttonwrap {
            flex: 1;
        }
    }

    .nav-toolbar::after {
        flex: 1;
        content: '';
    }
}

.MuiDrawer-paper {
    background-color: #3b3b3b !important;
    color: #fff200 !important;

    .MuiTypography-root {
        font-weight: bold;
    }
}