@media screen and (max-width: 900px) {
    .mixeslist-wrapper {
        padding: 0px !important;
    }    
}

.mixedgases-root {
    .mixedgases-mixeslist {
        padding: 50px 0;
        text-align: center;

        .mixeslist-title {
            padding-bottom: 50px;
        }
    }

    .mixedgases-grades {
        background-color: #B2DAB3;
        color: white;
        padding: 50px 0;

        .grades-title {
            text-align: center;
            padding-bottom: 50px;
        }
    }
}