.nomatch-root {
    padding: 100px 0;
    text-align: center;
    
    .nomatch-error {
        font-weight: 600;
    }

    .nomatch-title {
        margin-bottom: 25px;
    }
    
    .nomatch-button {
        margin-top: 50px;
        background-color: #fff200;
        color: #3b3b3b;
    }

    .nomatch-button:hover {
        background-color: #DBCB5D;
    }
}